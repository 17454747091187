import Accordion, { AccordionProps } from '@components/views/Accordion';
import SmallAccordion from '@components/views/FeaturedWork/Accordions';

interface AccordionGroupProps {
  accordion_collection: AccordionProps[];
  variant?: 'SMALL';
}

/**
 *
 * @link https://www.figma.com/file/bEoq4a8N0Yy6hvVvwAQxiS/WSC-%3A%3A-UI-Design?node-id=689%3A11859
 */
const AccordionGroup = (props: AccordionGroupProps) => {
  const { accordion_collection, variant } = props;

  if (!accordion_collection || accordion_collection.length < 0) return null;

  if (variant === 'SMALL')
    return (
      <SmallAccordion
        items={accordion_collection.map((item) => ({
          title: item.heading,
          content: item.body.content,
        }))}
      />
    );

  return (
    <div className={'relative z-10'}>
      {accordion_collection.map((el, i) => {
        const isLastElement = i === accordion_collection.length - 1;
        const hasContent = el.body.content || el.body.content;
        return (
          hasContent && (
            <Accordion
              {...el}
              key={`accordion-${i}`}
              isLastElement={isLastElement}
            />
          )
        );
      })}
    </div>
  );
};

export default AccordionGroup;
