import ChevronRightIcon from '../../../assets/images/icons/chevron-right.svg';
import React, { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { Collapse as ReactCollapse, CollapseProps } from 'react-collapse';

const CollapseStyled = styled.div<{ minHeight?: number }>`
  .ReactCollapse--collapse {
    transition: height 150ms;
    min-height: ${({ minHeight }) =>
      minHeight ? `${minHeight + 8}px` : '0px'};
  }
`;

interface CollapseAdditionalProps {
  variant?: 'BORDERED' | 'BORDERED-LAST' | 'SINGLE';
  titleNode?: ReactNode;
  noMargin?: boolean;
  withHover?: boolean;
  smallPadding?: boolean;
  buttonPlacement?: 'TOP' | 'BOTTOM';
  onButtonClick?: (isActive: boolean) => void;
  minHeight?: number;
  isDisabled?: boolean;
}

/**
 * Accordion
 * @link https://www.figma.com/file/bEoq4a8N0Yy6hvVvwAQxiS/WSC-%3A%3A-UI-Design?node-id=689%3A11739
 */
const Collapse: React.FunctionComponent<
  CollapseProps & CollapseAdditionalProps
> = (props) => {
  const {
    title,
    children,
    isOpened,
    variant,
    titleNode,
    noMargin,
    withHover,
    smallPadding,
    buttonPlacement = 'TOP',
    onButtonClick,
    minHeight,
    isDisabled,
  } = props;
  const [isActive, setActive] = useState(isOpened);

  const isSomeBorderedFamilyVariant =
    variant === 'BORDERED' || variant === 'BORDERED-LAST';

  useEffect(() => {
    setActive(isOpened);
  }, [isOpened]);

  useEffect(() => {
    if (onButtonClick) {
      onButtonClick(isActive);
    }
  }, [isActive]);

  const Button = (
    <button
      className={clsx(
        `flex items-center w-full text-neutral-9 text-left collapseButton`,
        {
          'pt-32 pb-24 font-extrabold text-base': isSomeBorderedFamilyVariant,
          'mb-24':
            !isSomeBorderedFamilyVariant && variant !== 'SINGLE' && !noMargin,
          'justify-between text-sm': variant !== 'SINGLE',
          'mb-40': variant === 'SINGLE' && !noMargin,
          'transition-colors hover:bg-neutral-1': withHover,
          '!pb-12': smallPadding,
        },
      )}
      onClick={() => {
        if (!isDisabled) {
          setActive(!isActive);
        }
      }}
    >
      <div
        className={clsx({
          'font-extrabold ': variant !== 'SINGLE',
          'mr-10': variant === 'SINGLE',
        })}
      >
        {titleNode && titleNode}
        {!titleNode && !!title && (
          <span dangerouslySetInnerHTML={{ __html: title }} />
        )}
      </div>
      <div
        className={clsx('transition-transform relative', {
          'rotate-90': !isActive,
          '-rotate-90': isActive,
          'right-18': withHover,
        })}
      >
        <ChevronRightIcon className="fill-neutral-9" />
      </div>
    </button>
  );

  return (
    <CollapseStyled
      className={clsx({
        'mx-32': !isActive && isSomeBorderedFamilyVariant,
        'border-b border-neutral-2': !isActive && variant === 'BORDERED',
        //TODO: remove this comment after merge PR#39 shadow-elevation3 is added on another branch. shadow will be added after merge both branches
        'px-32 bg-neutral-0 shadow-elevation3':
          isActive && isSomeBorderedFamilyVariant,
      })}
      minHeight={minHeight}
    >
      {buttonPlacement === 'TOP' && Button}
      <ReactCollapse {...props} isOpened={isActive}>
        {children}
      </ReactCollapse>
      {/*{minHeight && !isActive && <StyledGradient height={minHeight} />}*/}

      {buttonPlacement === 'BOTTOM' && Button}
    </CollapseStyled>
  );
};

export default Collapse;
