import { IButtonACF } from 'src/types/IButtonACF';
import Button from '@components/views/Button';
import Collapse from '@components/views/Collapse';
import omit from 'lodash/omit';

export interface AccordionProps {
  heading: string;
  body: {
    content: string;
    button?: IButtonACF;
  };
}

const Accordion = (props: AccordionProps & { isLastElement?: boolean }) => {
  const {
    heading,
    body: { content, button },
    isLastElement,
  } = props;
  const hasButton = button?.title && button?.url;
  const collapseVariant = !isLastElement ? 'BORDERED' : 'BORDERED-LAST';

  return (
    <Collapse isOpened={false} title={heading} variant={collapseVariant}>
      <div className="pb-32 grid gap-16">
        <p className="text-neutral-5">
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </p>
        {hasButton && (
          <Button {...omit(button, ['title', 'url'])} href={button.url}>
            {button.title}
          </Button>
        )}
      </div>
    </Collapse>
  );
};

export default Accordion;
