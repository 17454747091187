import React, { useState } from 'react';
import { Collapse as ReactCollapse } from 'react-collapse';
import clsx from 'clsx';
import ChevronRightIcon from '../../../../assets/images/icons/chevron-right.svg';
import styled from 'styled-components';

interface Accordion {
  title: string;
  content: string;
}

export interface AccordionsProps {
  items: Accordion[];
}

const CollapseStyled = styled.div`
  .ReactCollapse--collapse {
    transition: height 150ms;
  }
`;

const Accordion = ({
  accordion,
  isOpened = false,
}: {
  accordion: Accordion;
  isOpened?: boolean;
}) => {
  const [isActive, setActive] = useState(isOpened);

  const Button = (
    <button
      className="flex items-center w-full text-neutral-9 text-left justify-between pt-12 pb-24 px-16 relative"
      onClick={() => {
        setActive(!isActive);
      }}
    >
      <div className="font-extrabold mr-10">
        <span dangerouslySetInnerHTML={{ __html: accordion.title }} />
      </div>
      <div
        className={clsx('transition-transform relative', {
          'rotate-90': !isActive,
          '-rotate-90': isActive,
        })}
      >
        <ChevronRightIcon className="fill-neutral-9" />
      </div>
      <div className="h-1 w-full absolute bottom-0 left-0 bg-neutral-2" />
    </button>
  );

  return (
    <CollapseStyled>
      {Button}
      <ReactCollapse isOpened={isActive}>
        <div
          className="px-16 leading-[2.88rem] py-24"
          dangerouslySetInnerHTML={{ __html: accordion.content }}
        />
      </ReactCollapse>
    </CollapseStyled>
  );
};

const Accordions: React.FunctionComponent<AccordionsProps> = (props) => {
  const { items } = props;

  return (
    <div>
      {items.map((item) => (
        <Accordion key={item.content} accordion={item} />
      ))}
    </div>
  );
};

export default Accordions;
